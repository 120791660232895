<template>
  <div>
    <article class="item" v-for="nobelList in nobelList_data" :key="nobelList.id">
      <router-link :to="'/nobel/'+ nobelList.slug " class="thumb">
        <DefaultImage :id="nobelList.profile_image" :width="'80px'" :height="'80px'" />
      </router-link>
      <div class="info">
        <h4 class="title usmall">
          <router-link :to="'/nobel/'+ nobelList.slug ">
            {{nobelList.name}}
          </router-link>
        </h4>
        <time>{{nobelList.title}}</time>
      </div>

      <div class="clear"></div>
    </article>
  </div>
</template>

<script>
  import DefaultImage from "../DefaultImage";
  import api from "../../services/api";

  export default {
    name: "OurNobelLaureates",
    components: {DefaultImage},
    data() {
      return {
        nobelList_data: []
      }
    },
    mounted() {
      this.getNobel()
    },
    methods: {
      async getNobel() {
        this.nobelList_data = await api.getNobelList(5);
      }
    },
    watch: {
      '$route.params': {
        handler() {
          this.getNobel()
        }
      }
    }
  }
</script>

<style scoped>

</style>
