<template>
  <div>
    <div v-if="loading" class="loading" style="width: 100%;height: 550px;">
      <Loading />
    </div>

    <carousel
      v-else
      class="events-image-slider owl-theme"
      :dots="false"
      :items="1"
      :nav="false"
    >
      <template slot="prev"><span class="prev">
      <i class="flaticon-left-arrow"></i>
    </span></template>

      <div class="item" v-for="img in slider" :key="img.id">
        <img :src="img.image_url" class="slider-img" alt="">
      </div>

      <template slot="next"><span class="next">
      <i class="flaticon-right-arrow"></i>
    </span></template>
    </carousel>
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel';
  import api from "../services/api";
  import Loading from "./Loading";
  export default {
    name: "NobelSlider",
    components: {
      Loading,
      carousel
    },
    props: {
      sliderData: {
        type: [Array, Object],
        default: undefined
      }
    },
    data() {
      return {
        slider: [],
        loading: true
      }
    },
    methods: {
      async getImages() {
        let result = this.sliderData;
        const imageIds = this.sliderData.map((member) => member.directus_files_id);
        const imagesData = await api.getAllImageUrl("filter[id][in]=" + imageIds.toString());
        result.map((data) => {
          let imageUrls = imagesData.filter(
              (details) => details.id === data.directus_files_id
          );
          if (imageUrls.length > 0) data.image_url = imageUrls[0].data.full_url;
        });
        this.slider = result
      }
    },
    async created() {
      this.loading = true;
      await this.getImages();
      this.loading = false
    },
    watch: {
      sliderData: {
        async handler() {
          this.loading = true;
          await this.getImages();
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .events-image-slider {
    position: relative;
    height: 570px;
    margin-bottom: 40px;

    .next, .prev {
      position: absolute;
      z-index: 3;
      color: #fff;
      width: 40px;
      background: #30bfd5;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      transform: translate(-50%);
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: 0.7;
      }
    }
    .next {
      right: -20px;
    }
    .prev {
      left: 20px;
    }
  }

  .slider-img {
    height: 550px;
    object-fit: cover;
  }
</style>
