import { render, staticRenderFns } from "./OurNobelLaureates.vue?vue&type=template&id=06d1ac1a&scoped=true&"
import script from "./OurNobelLaureates.vue?vue&type=script&lang=js&"
export * from "./OurNobelLaureates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d1ac1a",
  null
  
)

export default component.exports