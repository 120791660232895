<template>
  <div class="loading" v-if="nobel_data === undefined && !nobel_error">
    <Loading />
  </div>
  <div v-else-if="nobel_data">
    <section class="blog-details-area" id="directus_files_id" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="blog-details-desc">
              <div class="article-content">
                <h2>{{nobel_data.data[0].name}}</h2>
                <p class="quote-section">{{nobel_data.data[0].quote}}</p>
                <div class="row mt-4 mb-4">
                  <div class="col-lg-7 col-md-12" v-if="nobel_data.data[0].slider.length">
                    <NobelSlider :sliderData="nobel_data.data[0].slider" />
                  </div>
                  <div class="col-lg-7 col-md-12" v-else>
                    <div class="no-images">No images</div>
                  </div>
                  <div class="col-lg-5 col-md-12">
                    <aside class="widget-area" id="secondary">
                      <section class="widget widget-peru-posts-thumb">
                        <h3 class="widget-title border-0">Our Nobel Laureates</h3>
                        <div class="post-wrap">
                          <OurNobelLaureates />
                        </div>
                      </section>
                    </aside>
                  </div>
                </div>

                <p>{{nobel_data.data[0].description}}</p> <br/>
                <p v-html="nobel_data.data[0].story" class="story white-space-pre-wrap"></p>
              </div>

              <div class="article-footer">
                <div class="article-tags">
                  <span><i class='fa fa-share-alt'></i></span>

                  <a href="#">Share</a>
                </div>

                <div class="article-share">
                  <ul class="social">
                    <li>
                      <ShareNetwork
                              network="facebook"
                              :url="base_url"
                              :title="nobel_data.data[0].title"
                              :description="nobel_data.data[0].description"
                      >
                        <i class="flaticon-facebook" aria-hidden="true"></i>
                      </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                              network="twitter"
                              :url="base_url"
                              :title="nobel_data.data[0].title"
                              :description="nobel_data.data[0].description"
                      >
                        <i class="flaticon-twitter"></i>
                      </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                              network="linkedin"
                              :url="base_url"
                              :title="nobel_data.data[0].title"
                              :description="nobel_data.data[0].description"
                      >
                        <i class="flaticon-linkedin"></i>
                      </ShareNetwork>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import api from "../services/api";
  import Loading from "../components/Loading";
  import useSWRV from "swrv";
  import OurNobelLaureates from "../components/nobel/OurNobelLaureates";
  import NobelSlider from "../components/NobelSlider";

  export default {
    components: {
      NobelSlider,
      OurNobelLaureates,
      Loading
    },
    data() {
      return {
        base_url: window.location.href
      }
    },
    setup(props, { root }) {
      if (!root.$route.params.url) {
        root.$router.push({ name: '/' });
      }
      const {
        data: nobel_data,
        error: nobel_error,
        isValidating: nobel_isValidating
      } = useSWRV(() => `items/nobel_list?status=published&filter[slug]=${root.$route.params.url}&fields=*.*`, api.fetcher);

      return {
        nobel_data,
        nobel_error,
        nobel_isValidating
      };
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    methods: {
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
    },
    watch: {
      '$route.params': {
        immediate: true,
        handler() {
          window.scrollTo(0, 0);
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .page-title-content {
    ul li {
      font-size: 22px;
      color: #FC4040;
    }
  }

  .loading {
    height: 800px;
  }
  .loading.two {
    height: 300px;
  }
  .blog-details-area {
    padding-top: 140px;
    padding-bottom: 100px;

    .blog-details-desc .article-footer .article-share .social li {
      margin-left: 5px;
      cursor: pointer;
    }

    .quote-section {
      color: #7c6ff1;
    }
  }

  .no-images {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f1f1f1;
  }
</style>
